import React, { useState } from 'react'
import { Paper, Typography, Button, Grid } from '@material-ui/core'
import { useEffect } from 'react'
import Input from 'BaseComponents/Input'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { saveNote, getNotes } from 'Redux/ClinicTable/clinicTable.actions'
import './style.css'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paperStyle: {
    padding: '1rem',
    height: '100%',
  },
  btnAlign: {
    textAlign: 'center',
    paddingTop: '25px',
    paddingBottom: '15px',
  },
  heading: {
    color: '#282626',
    marginBottom: '25px',
  },
  noteDate: {
    fontSize: 'large',
    fontWeight: '600',
  },
  note: {
    fontSize: 'large',
    textAlign: 'justify',
    paddingRight: '20px',
  },
}))

const Notes = (props) => {
  const classes = useStyles()
  const [notes, setNotes] = useState([])
  const [newNote, setNewNote] = useState('')
  // const [errMsg, setErrMsg] = useState('')

  const getNewNote = (e) => {
    if (e) {
      if (e.target.value) {
        setNewNote(e.target.value)
      } else {
        setNewNote('')
      }
    }
  }

  useEffect(() => {
    if (props.clinicId) {
      props.getNotes(props.clinicId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicId])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (newNote.length > 2) {
      let sendValue = {
        id: null,
        clinicId: props.clinicId,
        note: newNote,
      }
      props.saveNote(sendValue)
    }
  }

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.NoteDetails &&
      props.clinicData.NoteDetails.response &&
      props.clinicData.NoteDetails.response.responseCode === 0
    ) {
      setNotes((notes) => [
        ...notes,
        { note: newNote, createdDate: formatDate(new Date()) },
      ])
      setNewNote('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.NoteDetails])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.Notes &&
      props.clinicData.Notes.response
    ) {
      if (props.clinicData.Notes.response.responseCode === 0) {
        let notelist = props.clinicData.Notes.response.data
        const notesUpdate = notelist.map((x) => ({
          ...x,
          createdDate: formatDate(x.createdDate),
        }))
        setNotes(notesUpdate)
      } 
      // else if (props.clinicData.Notes.response.responseCode === 102) {
      //   setErrMsg('Select clinic before adding note')
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.Notes])

  const formatDate = (date) => {
    if (date === null) {
      return null
    }
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear()
    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }
    //console.log(year,month,day,"data_here")
    if (isNaN(year)) {
      return ''
    } else {
      return [month, day, year].join('/')
    }
  }

  return (
    <div className="notesContainer">
      <Paper elevation={0} className={classes.paperStyle}>
        <div className="notesTab__container">
          <Typography variant="h6" className={classes.heading}>
            Notes
          </Typography>

          {notes.length ? (
            notes.map((note, i) => {
              return (
                <Grid key={i} container className="note_displayed">
                  <Grid item xs={3}>
                    <Typography className={classes.noteDate}>
                      {note.createdDate}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className={classes.note}>
                      {note.note}
                    </Typography>
                  </Grid>
                  <br />
                </Grid>
              )
            })
          ) : (
            <div className="notesTab__notFoundMessage">
              <Typography>{'No notes found'}</Typography>
            </div>
          )}
        </div>
        <div className="inputNote">
          <form onSubmit={handleSubmit} className="notesTab__form">
            <Input
              type="text"
              label="Enter note"
              // label={props.clinicId && 'Enter note'}
              value={newNote}
              onChange={getNewNote}
              className="notesTab__input"
              variant="outlined"
              // disabled={!props.clinicId && true}
              inputProps={{ maxLength: 1000 }}
            />
          </form>
          <div className="notesTab__btnContainer">
            <Button
              className="submit btn--white"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!newNote && true}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  clinicData: state.clinicTable,
})

const mapDispatchToProps = (dispatch) => ({
  saveNote: (values) => dispatch(saveNote(values)),
  getNotes: (values) => dispatch(getNotes(values)),
})

Notes.propTypes = {
  clinicData: PropTypes.object,
  saveNote: PropTypes.func,
  getNotes: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notes)
