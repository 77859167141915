import {
  LOGIN_RESPONSE,
  LOGOUT_RES,
} from './login.types'


const INITIAL_STATE = {
  loginResponse: {},
  logout: null,
}

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RESPONSE: {
      return { ...state, ...{ loginResponse: action.payload } }
    }
    case LOGOUT_RES: {
      return { ...state, ...{ logout: action.payload } }
    }
    default:
      return state
  }
}
export default loginReducer
