import React from 'react'
import { Toaster } from 'react-hot-toast'
import Login from 'Containers/Login'
import Clinics from 'Containers/Clinics'
import { PrivateRoute } from 'Utils/private-route'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'

const App = () => {
  return (
    <div className="app">
      {/* <IdleTimerContainer>hello</IdleTimerContainer> */}

      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        <React.Suspense fallback={'loading'}>
          <Switch>
            <PrivateRoute path="/login" component={Login} />
            <PrivateRoute path="/clinics" component={Clinics} />
            <Redirect from="/" to="/login" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  )
}

export default App
