import React from 'react'
import Button from '@material-ui/core/Button'
/**
 * @fileOverviewMain Manages Reusable Button, function for the Button component. It manages all the functionalities of the component
 * @component
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @param {props} props will contain all the latest values for the autocomplete like value, onChange,Label
 * @example
 * return(
 * <MatButton/>
 * )
 */
function MatButton(props) {
  return (
    <Button
      color={props.color ? props.color : ''}
      variant={props.variant ? props.variant : ''}
      {...props}
    >
      {props.btname ? props.btname : ''}
    </Button>
  )
}
export default MatButton
