import React, { useRef, useState } from 'react'
import IdleTimer from 'react-idle-timer'

const IdleTimerContainer = (props) => {
  const idleTimerRef = useRef(null)
  const [timeoutDuration] = useState(1000 * 60 * 30)

  const onIdleHandler = () => {
    props.handleLogout()
  }

  return (
    <IdleTimer
      ref={idleTimerRef}
      timeout={timeoutDuration}
      onIdle={onIdleHandler}
    />
  )
}

export default IdleTimerContainer
