import {
  LOGIN,
  LOGIN_RESPONSE,
  LOGOUT,
  LOGOUT_RES,
} from './login.types'


export const getLogin = (loginData) => ({
  type: LOGIN,
  payload: loginData,
})

export const getLoginResponse = (responseData) => ({
  type: LOGIN_RESPONSE,
  payload: responseData,
})

export const logoutApi = (logoutData) => ({
  type: LOGOUT,
  payload: logoutData,
})

export const logoutApiResponse = (logoutResponse) => ({
  type: LOGOUT_RES,
  payload: logoutResponse,
})